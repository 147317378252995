.assign-role-to-user-filter-confirm .ant-popover-inner {
  display: flex;
  width: 206px;
  height: 305px;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  background: var(--0, #fff);
  box-shadow: 0px 0px 40px 0px rgba(113, 130, 183, 0.4);
}

.assign-role-to-user-filter-confirm .ant-popover-inner-content {
  width: 100%;
}
.assign-role-to-user-filter-confirm .ant-popover-title {
  color: var(--text-3, #5d6881) !important;
  font-family: DM Sans !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  padding-bottom: 8px;
  border-bottom: 1px solid #f3f4f5;
  margin-bottom: unset;
}
