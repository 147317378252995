@font-face {
  font-family: DM Sans;
  src: url(/public/assets/fonts/en/PlusJakartaSans-Regular.ttf);
  font-weight: 1 400 normal;
}
@font-face {
  font-family: LEMON MILK;
  src: url(/public/assets/fonts/en/LEMONMILK-Regular.otf);
}

@font-face {
  font-family: DM Sans;
  src: url(/public/assets/fonts/plus/PlusJakartaSans-Medium.ttf);
  font-weight: 500;
}
@font-face {
  font-family: DM Sans;
  src: url(/public/assets/fonts/plus/PlusJakartaSans-SemiBold.ttf);
  font-weight: 600;
}
@font-face {
  font-family: DM Sans;
  src: url(/public/assets/fonts/plus/PlusJakartaSans-Bold.ttf);
  font-weight: 700 bold;
}
