@font-face {
  font-family: vazir;
  src: url(/public/assets/fonts/fa/Vazir-Regular-FD.ttf);
  font-weight: 1 400 normal;
}
@font-face {
  font-family: vazir;
  src: url(/public/assets/fonts/vazirFD/Vazir-Medium-FD.ttf);
  font-weight: 500;
}
@font-face {
  font-family: vazir;
  src: url(/public/assets/fonts/vazirFD/Vazir-Bold-FD.ttf);
  font-weight: 700;
}
@font-face {
  font-family: vazir;
  src: url(/public/assets/fonts/vazirFD/Vazir-Bold-FD.ttf);
  font-weight: bold;
}
@font-face {
  font-family: vazir;
  src: url(/public/assets/fonts/vazirFD/Vazir-Black-FD.ttf);
  font-weight: 800 900;
}
